<template>
	<div>
		<div class="d-block mb-4">
			<label class="label">
				SKUs
			</label>
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				data-test-id="button-add"
				@click="$refs['modal-sku'].open()"
			>
				{{ `${hasListItem ? 'Edit' : 'Add'} SKU` }}
			</CButton>
		</div>
		<div v-if="!localList.length" class="mb-3">
			<BaseNoItemBanner
				:class="{ 'is-invalid': !isValid }"
				text="There are no SKU for this rule"
			/>
			<div v-if="invalidFeedback" class="invalid-feedback">
				{{ invalidFeedback }}
			</div>
		</div>
		<ul v-else class="list-item show-border">
			<p class="show-text">
				{{ showText }}
			</p>
		</ul>

		<ModalSkuList
			ref="modal-sku"
			title="Select SKUs"
			:sku-list="localList"
			@onConfirm="handleSKUChange"
		/>
	</div>
</template>

<script>
import ModalSkuList from '@/components/ModalSkuList.vue';

export default {
	name: 'SearchWeightProductForm',
	components: {
		ModalSkuList,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			localList: [...this.list],
		};
	},
	computed: {
		hasListItem() {
			return this.list && this.list.length > 0;
		},
		showText() {
			return this.localList.join('; ');
		},
	},
	methods: {
		handleSKUChange(products) {
			this.localList = products;
			this.$emit('onChange', this.localList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.is-invalid {
		border: 1px solid $color-alert;
		border-radius: rem(4);
	}

	.list-item {
		padding: rem(20) rem(16) 0;
		max-height: rem(320);
		overflow: auto;
	}

	.show-text {
		font-size: rem($font-size-caption);
		font-weight: $font-weight-semi-bold;
		color: $color-black-45;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}
</style>
