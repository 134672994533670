<template>
	<div>
		<div class="d-block mb-4">
			<label class="label">
				Brands
			</label>
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				data-test-id="button-add"
				@click="$refs['modal-brand'].open()"
			>
				{{ `${hasListItem ? 'Edit' : 'Add'} brand` }}
			</CButton>
		</div>
		<div class="mt-3 brand-box" :class="{ 'is-invalid': !isValid }">
			<BaseNoItemBanner v-if="!hasListItem" text="There are no brand for this rule" />
			<ul v-else class="brand-list">
				<li v-for="item in list" :key="item.id" class="list-item typo-body-2">
					{{ item.name }}
				</li>
			</ul>
		</div>
		<div
			v-if="invalidFeedback"
			class="invalid-feedback"
		>
			{{ invalidFeedback }}
		</div>
		<ModalBrand
			ref="modal-brand"
			:list="list"
			data-test-id="modal-brand"
			@onConfirm="handleListChange"
		/>
	</div>
</template>

<script>
import ModalBrand from '@/components/ModalBrand.vue';

export default {
	name: 'SearchWeightBrandForm',
	components: {
		ModalBrand,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			localList: this.list,
		};
	},
	computed: {
		hasListItem() {
			return this.list && this.list.length > 0;
		},
	},
	methods: {
		handleListChange(list) {
			this.$emit('onChange', list);
		},
	},
};
</script>


<style lang="scss" scoped>
	.btn-add {
		min-width: rem(80);
	}

	.brand-box {
		&.is-invalid {
			border: 1px solid $color-alert;
			border-radius: rem(4);
		}
	}

	.brand-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		list-style: none;

		.list-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}
	}
</style>