var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('CInput',{staticClass:"flex-fill",attrs:{"placeholder":"Search by promotion name","autocomplete":"off","data-test-id":"input-promotion"},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{staticClass:"cil-magnifying-glass",attrs:{"name":"cil-magnifying-glass"}})]},proxy:true}]),model:{value:(_vm.promotion),callback:function ($$v) {_vm.promotion=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"promotion"}})],1),(!_vm.filteredList.length)?_vm._t("no-item"):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredList.length),expression:"filteredList.length"}],staticClass:"list"},[_c('div',{staticClass:"select-all d-flex mb-1 align-items-center"},[_c('CInputCheckbox',{staticClass:"select-all-input",attrs:{"checked":_vm.isSelectedAll,"custom":"","add-label-classes":"font-weight-bold","data-test-id":"select-all-input"},on:{"update:checked":[function($event){_vm.isSelectedAll=$event},_vm.handleCheckedSelectAll]}}),_vm._l((_vm.promotionsTableFields),function(head){return _c('div',{key:head.key,class:[
					'modal-col',
					'modal-col-header',
					head.class ? ("modal-" + (head.class)) : '' ]},[_vm._v(" "+_vm._s(head.label)+" ")])})],2),_c('DynamicScroller',{staticClass:"scroller list-select",attrs:{"items":_vm.filteredList,"min-item-size":35,"key-field":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var item = ref.item;
				var index = ref.index;
				var active = ref.active;
return [_c('DynamicScrollerItem',{staticClass:"list-item",attrs:{"item":item,"active":active,"data-index":index}},[_c('div',{staticClass:"d-flex flex-fill"},[_c('CInputCheckbox',{staticClass:"list-checkbox overflow-hidden",attrs:{"id":("promotion-checkbox-" + (item.id)),"checked":_vm.checkItemIsSelected(item.id),"custom":""},on:{"update:checked":function($event){return _vm.handleCheckedPromotion($event, item)}}}),_vm._l((_vm.promotionsTableFields),function(data){return _c('div',{key:data.key,class:[
								'modal-col',
								'modal-col-data',
								data.class ? ("modal-" + (data.class)) : '' ]},[(data.key === 'title')?[_vm._v(" "+_vm._s(item[data.key].name)+" ")]:(data.key === 'type')?[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item[data.key].name)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(item[data.key].reward)+" ")])]:(data.key === 'periodStatus')?[_c('CBadge',{staticClass:"badge-period",attrs:{"color":item[data.key].color}},[_vm._v(" "+_vm._s(item[data.key].title)+" ")])]:[_vm._v(" "+_vm._s(item[data.key])+" ")]],2)})],2)])]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }