<template>
	<div>
		<div class="d-block mb-4">
			<label class="label">
				Categories
			</label>
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				data-test-id="button-add"
				@click="$refs['modal-category'].open()"
			>
				{{ `${hasListItem ? 'Edit' : 'Add'} category` }}
			</CButton>
		</div>
		<div class="mt-3 category-box" :class="{ 'is-invalid': !isValid }">
			<BaseNoItemBanner v-if="!hasListItem" text="There are no category for this rule" />
			<ul
				v-else
				class="category-list"
			>
				<li
					v-for="category in localList"
					:key="category.id"
					class="list-item typo-body-2"
				>
					<span
						v-for="(path, index) in category.pathName"
						:key="index"
						class="category-name"
					>
						{{ path }}
					</span>
				</li>
			</ul>
		</div>
		<div
			v-if="invalidFeedback"
			class="invalid-feedback"
		>
			{{ invalidFeedback }}
		</div>
		<ModalCategory
			ref="modal-category"
			title="Select categories"
			:selected-ids="categoryIds"
			@onSubmit="handleSubmitCategory"
			@onCancel="handleCancelCategoryModal"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalCategory from '@/components/ModalCategory.vue';

export default {
	name: 'SearchWeightCategoryForm',
	components: {
		ModalCategory,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			localList: this.list,
		};
	},
	computed: {
		...mapGetters({
			getSelectedCategories: 'categorySelector/getSelectedCategories',
		}),
		hasListItem() {
			return this.localList && this.localList.length > 0;
		},
		categoryIds() {
			return this.localList.map((category) => category.id);
		},
	},
	async mounted() {
		await this.getCategories();
		this.preSelectCategories(this.list.map((category) => category.id));
		this.localList = this.getSelectedCategories;
	},
	methods: {
		...mapActions({
			getCategories: 'categorySelector/getCategories',
			preSelectCategories: 'categorySelector/preSelectCategories',
		}),
		handleSubmitCategory(categories = []) {
			this.localList = categories;
			this.$emit('onChange', this.localList);
		},
		handleCancelCategoryModal() {
			// Reset category data when user close/cancel category modal
			this.preSelectCategories(this.categoryIds);
			this.$emit('onChange', this.localList);
		},
	},
};
</script>


<style lang="scss" scoped>
	.btn-add {
		min-width: rem(80);
	}

	.category-box {
		&.is-invalid {
			border: 1px solid $color-alert;
			border-radius: rem(4);
		}
	}

	.category-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		list-style: none;
	}

	.list-item {
		border-radius: rem(4);
		background-color: $color-gray-300;
		color: $color-black-90;
		display: inline-block;
		padding: 0 rem(6);
		margin-right: rem(12);
		margin-bottom: rem(12);

		.category-name {
			color: $color-black-45;

			&::after {
				content: "/";
				display: inline-block;
			}

			&:last-child {
				color: $color-black-90;
				font-weight: 600;

				&::after {
					display: none;
				}
			}
		}
	}
</style>