<template>
	<div>
		<label class="label d-block">
			Price
		</label>
		<div class="category-box">
			<CRow>
				<CCol md="4" class="pr-2">
					<BaseDropDown
						v-model="localCondition"
						:options="SEARCH_WEIGHT_PRICE_CONDITION_OPTIONS"
						:searchable="false"
						:allow-empty="false"
						label="name"
						track-by="value"
						class="select-custom"
						@input="handleSelectCondition"
					/>
				</CCol>
				<CCol md="3" class="pl-2">
					<BaseInputNumber
						v-model="localPrice"
						:decimal-scale="2"
						:is-valid="isValid"
						:invalid-feedback="invalidFeedback"
						placeholder="0.00"
						prepend-text="฿"
						text-align="right"
						@input="handlePriceChange"
					/>
				</CCol>
			</CRow>
		</div>
	</div>
</template>

<script>
import { SEARCH_WEIGHT_PRICE_CONDITION_OPTIONS } from '../enums/searchWeights';

export default {
	name: 'SearchWeightPriceForm',
	props: {
		condition: {
			type: String,
			default: null,
		},
		price: {
			type: [String, Number],
			default: null,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			SEARCH_WEIGHT_PRICE_CONDITION_OPTIONS,
			localCondition: SEARCH_WEIGHT_PRICE_CONDITION_OPTIONS.find((option) => option.value === this.condition) || SEARCH_WEIGHT_PRICE_CONDITION_OPTIONS[0],
			localPrice: this.price,
		};
	},
	methods: {
		handleSelectCondition({ value }) {
			this.$emit('onChange', { condition: value, price: this.localPrice });
		},
		handlePriceChange(value) {
			this.$emit('onChange', { condition: this.localCondition.value, price: value });
		},
	},
};
</script>