<template>
	<div>
		<div>
			<CInput
				v-model.trim="promotion"
				placeholder="Search by promotion name"
				class="flex-fill"
				autocomplete="off"
				data-test-id="input-promotion"
			>
				<template #prepend-content>
					<CIcon
						class="cil-magnifying-glass"
						name="cil-magnifying-glass"
					/>
				</template>
			</CInput>
		</div>
		<slot v-if="!filteredList.length" name="no-item"></slot>
		<div v-show="filteredList.length" class="list">
			<div class="select-all d-flex mb-1 align-items-center">
				<CInputCheckbox
					:checked.sync="isSelectedAll"
					custom
					class="select-all-input"
					add-label-classes="font-weight-bold"
					data-test-id="select-all-input"
					@update:checked="handleCheckedSelectAll"
				/>
				<div
					v-for="head in promotionsTableFields"
					:key="head.key"
					:class="[
						'modal-col',
						'modal-col-header',
						head.class ? `modal-${head.class}` : '',
					]"
				>
					{{ head.label }}
				</div>
			</div>
			<DynamicScroller
				:items="filteredList"
				:min-item-size="35"
				key-field="id"
				class="scroller list-select"
			>
				<template v-slot="{ item, index, active }">
					<DynamicScrollerItem
						:item="item"
						:active="active"
						:data-index="index"
						class="list-item"
					>
						<div class="d-flex flex-fill">
							<CInputCheckbox
								:id="`promotion-checkbox-${item.id}`"
								:checked="checkItemIsSelected(item.id)"
								custom
								class="list-checkbox overflow-hidden"
								@update:checked="handleCheckedPromotion($event, item)"
							/>
							<div
								v-for="data in promotionsTableFields"
								:key="data.key"
								:class="[
									'modal-col',
									'modal-col-data',
									data.class ? `modal-${data.class}` : '',
								]"
							>
								<template v-if="data.key === 'title'">
									{{ item[data.key].name }}
								</template>
								<template v-else-if="data.key === 'type'">
									<div class="name">
										{{ item[data.key].name }}
									</div>
									<div class="caption">
										{{ item[data.key].reward }}
									</div>
								</template>
								<template
									v-else-if="data.key === 'periodStatus'"
								>
									<CBadge
										class="badge-period"
										:color="item[data.key].color"
									>
										{{ item[data.key].title }}
									</CBadge>
								</template>
								<template v-else>
									{{ item[data.key] }}
								</template>
							</div>
						</div>
					</DynamicScrollerItem>
				</template>
			</DynamicScroller>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { PROMOTIONS_TABLE_FIELDS } from '../enums/promotions';

export default {
	name: 'FormGetPromotion',
	components: {
		DynamicScroller,
		DynamicScrollerItem,
	},
	props: {
		excludeIds: {
			type: Array,
			default: null,
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isSelectedAll: false,
			promotion: '',
			promotionList: [],
		};
	},
	computed: {
		...mapGetters({
			getPromotionList: 'promotions/getPromotionList',
		}),
		promotionsTableFields() {
			const pickedKeys = ['id', 'title', 'type', 'periodStatus'];
			return PROMOTIONS_TABLE_FIELDS.filter((item) => pickedKeys.includes(item.key));
		},
		filteredList() {
			if (!this.promotion) {
				return this.promotionList;
			}

			return this.promotionList.filter((promotion) => promotion.name
				.toLowerCase()
				.startsWith(this.promotion.toLowerCase()));
		},
	},
	watch: {
		filteredList() {
			this.checkSelectionState();
		},

		selectedList() {
			this.checkSelectionState();
		},
	},
	async created() {
		await this.getPromotions({ per_page: 'all' });

		this.promotionList = this.excludeIds && Array.isArray(this.excludeIds) && this.excludeIds.length > 0
			? this.getPromotionList.filter((promo) => this.excludeIds.indexOf(parseInt(promo.id, 10)) === -1)
			: this.getPromotionList;
	},
	methods: {
		...mapActions({
			getPromotions: 'promotions/getPromotions',
		}),
		resetState() {
			this.promotion = '';
		},
		checkItemIsSelected(val) {
			return this.selectedList.some((item) => item.id === val);
		},
		checkSelectionState() {
			const isEverySelected =
				this.filteredList.length &&
				this.filteredList.every((promotion) => this.selectedList
					.map((item) => item.id)
					.includes(promotion.id));
			const isSomeSelected =
				this.filteredList.length &&
				this.filteredList.some((promotion) => this.selectedList
					.map((item) => item.id)
					.includes(promotion.id));

			if (isEverySelected) {
				this.setSelectedAllState('checked');
				return;
			}

			if (isSomeSelected) {
				this.setSelectedAllState('indeterminated');
				return;
			}

			this.setSelectedAllState('unchecked');
		},
		setSelectedAllState(state) {
			let isChecked = false;
			let isIndeterminate = false;
			switch (state) {
				case 'checked': {
					isChecked = true;
					isIndeterminate = false;
					break;
				}
				case 'unchecked': {
					isChecked = false;
					isIndeterminate = false;
					break;
				}
				case 'indeterminated': {
					isChecked = true;
					isIndeterminate = true;
					break;
				}
				default:
					break;
			}

			const selectAllEle = this.$el.querySelector('.select-all-input input');
			if (selectAllEle) {
				selectAllEle.checked = isChecked;
				selectAllEle.indeterminate = isIndeterminate;
			}
		},
		handleCheckedSelectAll() {
			if (this.isSelectedAll) {
				const selectedItems = this.filteredList;
				this.addToSelectedList(selectedItems);
			} else {
				const selectedItems = this.filteredList.map((item) => item.id);
				this.removeFromSelectedList(selectedItems);
			}
		},
		handleCheckedPromotion(checked, promotion) {
			const isSelected = this.selectedList.some((item) => item.id === promotion.id);
			if (!isSelected) {
				this.addToSelectedList(promotion);
			} else {
				this.removeFromSelectedList(promotion.id);
			}
		},

		/**
		 * @param {String|Array} items Promotion or List of promotions
		 */
		addToSelectedList(items = []) {
			let newItems = items;
			if (!Array.isArray(items)) {
				newItems = [items];
			}
			const uniqueItems = [
				...new Set([...this.selectedList, ...newItems]),
			].sort();
			this.$emit('onChange', uniqueItems);
		},

		/**
		 * @param {String|Array} items Promotion or List of promotions
		 */
		removeFromSelectedList(items = []) {
			let itemsToRemove = items;
			if (!Array.isArray(items)) {
				itemsToRemove = [items];
			}

			this.$emit('onChange', this.selectedList.filter((selectedItem) => !itemsToRemove.includes(selectedItem.id)));
		},
	},
};
</script>

<style lang="scss" scoped>
	.scroller {
		height: 100%;
	}

	.btn-add {
		min-width: rem(84);
	}

	.list {
		margin: 0 -#{rem(16)} -#{rem(16)};
	}

	.select-all {
		background-color: $color-gray-100;
		padding: rem(8) rem(16);
	}

	.list-select {
		min-height: rem(360);
		max-height: rem(360);
		list-style: none;
		padding: 0;
		margin: 0;

		.list-item {
			padding: rem(6) rem(16);

			label {
				margin-top: rem(3);
				cursor: pointer;
			}
		}
	}

	.caption {
		font-size: rem($font-size-caption);
		font-weight: $font-weight-bold;
		color: $color-black-45;
	}

	.modal-col {
		flex: 1;
		padding: 0 rem(24);
		color: $color-black-90;

		&-header {
			font-weight: $font-weight-bold;
		}

		&-id {
			max-width: rem(64);
		}

		&-type {
			max-width: rem(120);
		}

		&-period {
			max-width: rem(120);
		}
	}
</style>
