<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			size="lg"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="title"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetPromotion
							ref="promotion-form"
							:selected-list="selectedList"
							:exclude-ids="excludeIds"
							@onChange="handleListChange"
						>
							<template #no-item>
								<div
									class="text-center mt-5 mb-5"
									data-test-id="no-item"
								>
									<div class="typo-body-1 font-weight-bolder color-black-45">
										Search not found
									</div>
									<div class="typo-body-2 color-black-45">
										We didn't find any promotions matching your criteria
									</div>
								</div>
							</template>
						</FormGetPromotion>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:total-select="selectedList.length"
					@onCancel="close"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
		<BaseModalConfirm
			v-if="needConfirmation"
			ref="modal-confirm"
			:title="titleConfirm"
			:description="descriptionConfirm"
			@onConfirm="handleConfirm"
		/>
	</div>
</template>


<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import FormGetPromotion from '@/components/FormGetPromotion.vue';

export default {
	name: 'ModalPromotion',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		FormGetPromotion,
		BaseModalConfirm,
	},
	props: {
		title: {
			type: String,
			default: 'Add promotion',
		},
		titleConfirm: {
			type: String,
			default: null,
		},
		descriptionConfirm: {
			type: String,
			default: null,
		},
		needConfirmation: {
			type: Boolean,
			default: false,
		},
		excludeIds: {
			type: Array,
			default: null,
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			selectedList: [...this.list],
		};
	},
	methods: {
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		submit() {
			this.isShow = false;
			this.$emit('onConfirm', this.selectedList);
		},
		resetState() {
			const originalList = [...this.list];
			this.selectedList = originalList;
			this.$refs['promotion-form'].resetState(originalList);
		},
		handleListChange(list) {
			this.selectedList = list;
		},
		handleSubmit() {
			if (this.needConfirmation) {
				this.$refs['modal-confirm'].open();
			} else {
				this.submit();
			}
		},
		handleConfirm() {
			this.submit();
		},
	},
};
</script>
